import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { DiputadosComponent } from './diputados/diputados.component';
import { RankingComponent } from './ranking/ranking.component';
import { PerfilComponent } from './perfil/perfil.component';
import { BancadaComponent } from './bancada/bancada.component';
import { NewsComponent } from './novedades/news.component';
import { NewsContentComponent} from './novedades/news-content.component';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import { CountUpModule } from 'ngx-countup';

import { HttpClientModule } from '@angular/common/http';




import { GraphComponent } from '../components/graph.component';
import { UbicaTuDiputadoComponent } from './ubica-tu-diputado/ubica-tu-diputado.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewsService } from './novedades/news.service';
import { AlternativeRankingComponent } from './alternative-ranking/alternative-ranking.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { CommissionsComponent } from './commissions/commissions.component';
import { CommissionPoliticiansComponent } from './commission-politicians/commission-politicians.component';









@NgModule({
  declarations: [
    HomeComponent,
    DiputadosComponent,
    RankingComponent,
    PerfilComponent,
    GraphComponent,
    BancadaComponent,
    UbicaTuDiputadoComponent,
    NewsComponent,
    NewsContentComponent,
    AlternativeRankingComponent,
    NewsletterComponent,
    CommissionsComponent,
    CommissionPoliticiansComponent
  ],
  imports: [
    CommonModule,
    ContentLoaderModule,
    NgxChartsModule,
    NgbRatingModule,
    CountUpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [],
  providers:[
    NewsService,
    FormsModule
  ],
})
export class PagesModule { }
