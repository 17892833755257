import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NewsService {
  newsCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.newsCollection = this.afs.collection('news', (ref) => ref.orderBy('time', 'desc').limit(5));
  }

  getData(): Observable<any[]> {
    // ['added', 'modified', 'removed']
    return this.newsCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id, ...data };
        });
      })
    );
  }

  getNews(id: string) {
    return this.afs.doc<any>(`news/${id}`);
  }

 

  updateNews(id: string, data: any) {
    return this.getNews(id).update(data);
  }


}
