import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { HttpClient } from '@angular/common/http';


import { Observable } from 'rxjs';
import { map } from 'rxjs';


export class Distrito {
  distritos: [object];
}



@Injectable()
export class UbicaTuDiputadoService {

  districtCollection: AngularFirestoreCollection<any>;
  politicianCollection: AngularFirestoreCollection<any>;
  commissionCollection:AngularFirestoreCollection<any>;
  politicians: Observable<any[]>;
  url: string;
  path: string;

  constructor(private afs: AngularFirestore, private http: HttpClient) {
    this.politicianCollection = this.afs.collection('politicians', (ref) => ref.orderBy('name', 'asc'))
    this.districtCollection = this.afs.collection('districts', (ref) => ref.orderBy('id_district', 'asc'))
    this.commissionCollection = this.afs.collection('commissions',(ref)=>ref.orderBy('name','asc'));

  }



  getDistricts(): Observable<any[]> {
    // ['added', 'modified', 'removed']
    return this.districtCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id, ...data };
        });
      })
    );
  }


  getPolitician(): Observable<any[]> {
    // ['added', 'modified', 'removed']
    return this.politicianCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id, ...data };
        });
      })
    );
  }

  getCommissions():Observable<any[]>{
    return this.commissionCollection.snapshotChanges().pipe(
      map((actions)=>{
        return actions.map((a)=>{
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id , ...data }
        })

      })
    )
  }


  getPoliticianByDistrictID(idDistrict: number) {
    return this.afs.collection('politicians',(ref)=>ref.where("district.value", "==", Number(idDistrict))).snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => { 
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id, ...(data as object) };
        });
      })
    );
  }


  getPoliticianBySection(section: string) {
    return this.afs.collection('districts', (ref) => ref.where('sections', 'array-contains', section).limit(1)).valueChanges();
  }

  getPoliticianByElection(election:string){
    return this.afs.collection('politicians',(ref)=>ref.where('election','==',election)).snapshotChanges().pipe(
       map((actions)=>{
         return actions.map((a)=>{
           const data = a.payload.doc.data();
           return { id: a.payload.doc.id, ...(data as object) };
         })
       })
    )
  }


  getURL(url: string) {
    return this.url = url;
  }

  setURL() {
    return this.url; 
  }

  setPath(path: string) {
    return this.path = path;
  }

  getPath() {
    return this.path;
  }

}
