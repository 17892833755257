import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommissionService } from './commission.service';

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.scss'],
  providers: [CommissionService],
})

export class CommissionsComponent implements OnInit {
  politicians: Observable<any[]>;
  commission: Observable<any[]>;
  constructor( private commissionService:CommissionService) { }

  ngOnInit() {
    this.commission = this.commissionService.getCommissions();
    this.politicians = this.commissionService.getPolitician();
    this.cargarDatosTabla();
  }

  comisiones:any[];
  comisionesSelect:any[];
  diputados:any[];
  commission_id:number;



  cargarDatosTabla(){
    this.politicians.forEach((poldata:any)=>{
      this.diputados = poldata;
    });


    this.commission.forEach((poldata:any)=>{
      this.comisiones = poldata;
      this.comisionesSelect = poldata;

      this.comisiones.sort((a:any,b:any)=>Number(a.commission_id) - Number(b.commission_id))
      .map(comision=>{    
        let count = 0;    
      this.diputados.map(d=>{
         const diputadosComision = d.commissions.filter(c=>c.commission_Id === comision.commission_id);
         if(diputadosComision.length > 0){
           count = count + 1
         }
         
      })
      comision.countPolitician = count;
      })
      
    
    });
  }

  selectOptionCommission(value) {
    this.commission_id = value;
  }

  buscarPorComision(){
    this.comisiones = this.comisionesSelect;

    if(!this.commission_id){
      alert('Selecciona una opción y vuelvelo a intentar')
      return;
    }
    if(this.commission_id == 0){

      this.comisiones = this.comisionesSelect;
      return;
    }

    this.comisiones = this.comisiones.filter(c=>c.commission_id === this.commission_id);
  }



}
