import { Component, OnInit } from '@angular/core';
import { UbicaTuDiputadoService } from './ubica-tu-diputado.service';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { municipios } from './municipios';




@Component({
  selector: 'ubica-tu-diputado',
  templateUrl: './ubica-tu-diputado.component.html',
  styleUrls: ['./ubica-tu-diputado.component.scss'],
  providers: [UbicaTuDiputadoService , ]
})
export class UbicaTuDiputadoComponent implements OnInit {


  downloadURL: Observable<any>;
  distritos: Observable<any[]>;
  politicians: Observable<any[]>;
  commissions: Observable<any[]>;
  partidosBD: Observable<any[]>;
  listDistritos: any[];
  listCommissions:any[];
  listMunicipalities:[];
  perfilFotoURL: Observable<any>;

  politicianDistrictFound: any;
  politiciansMunicipalitiesFound:any[];
  politiciansElectionFound:any[];
  politiciansCommissionFound:any[];



  constructor(private politiciansService: UbicaTuDiputadoService, private storage: AngularFireStorage) { }


  ngOnInit() {
    this.distritos = this.politiciansService.getDistricts();
    this.politicians = this.politiciansService.getPolitician();
    this.commissions = this.politiciansService.getCommissions();
    this.cargarDatosTabla();
    this.section;
    this.electionSelected = 'MR';
    this.distSelected = 1;
    this.municipalitySelected = 1;
    this.commissionSelected = 2;

  }

  selectOption(id) {
    //getted from event
    this.distSelected = id;
  }

  selectOptionMunicipality(id){
    this.municipalitySelected = id;
  }

  selectOptionElection(election){
  
    this.electionSelected = election;
  }


  selectOptionCommission(commission){
    this.commissionSelected = commission;
  }

  diputados: any[];
  senadores: any[];
  partidos: any[];
  id: any[];
  urlFilter: string;
  dURL: Observable<any>;
  buscar: boolean;

  distSelected: number;
  municipalitySelected:number;
  commissionSelected:number;
  distBySection: any;
  electionSelected:string;

  section: string;


  cargarDatosTabla() {
    this.buscar = true;

    this.listDistritos = [];
    this.listMunicipalities=municipios;

    this.distritos.forEach((poldata) => {
      this.listDistritos = poldata;
    });

    this.commissions.forEach((poldata)=>{
      this.listCommissions = poldata;
    })

    this.politicians.forEach((poldata) => {
      this.diputados = poldata;
      for (let i = 0; i < this.diputados.length; i++) {
        this.diputados[i].image_path = this.storage
          .ref(this.diputados[i].image_path)
          .getDownloadURL();
      }
    });

  }

  buscarPorDistrito() {
    this.politiciansService.getPoliticianByDistrictID(this.distSelected)
       .subscribe((politicianDB) => {
       this.politicianDistrictFound = politicianDB[0];
       if (this.politicianDistrictFound == null || this.politicianDistrictFound.name == null) {
         alert("Sin resultados, vuelve a intentar")
       } else {
         this.perfilFotoURL = this.storage.ref(this.politicianDistrictFound.image_path).getDownloadURL();
         this.buscar = false;

       }

    })

  }

  buscarPorMunicipio(){
    
    const municipality = municipios.filter(m=>m.value == this.municipalitySelected);    
    const { districts } = municipality[0];
    districts.forEach((element) => {
       this.obtenerDiputado(element.district_id);
    });

  }

  obtenerDiputado(district){
    

    this.politiciansService.getPoliticianByDistrictID(district).subscribe((politicianDB)=>{
            politicianDB.forEach((politician:any)=>{
              politician.image_path = this.storage.ref(politician.image_path).getDownloadURL();
              return politician;
            });

            const politiciansAdd = this.politiciansMunicipalitiesFound.filter((m)=>m.id == politicianDB[0].id );

            if(politiciansAdd.length === 0){
              this.politiciansMunicipalitiesFound = [ ...this.politiciansMunicipalitiesFound , politicianDB[0]]
            }  
       this.buscar = false;
     }); 

  }

  buscarPorTipoEleccion(){    
    this.politiciansService.getPoliticianByElection(this.electionSelected).subscribe((politicianDB) => {
      if(!politicianDB.length){
        alert("Sin resultados, vuelve a intentar")
      }else{
        
        this.politiciansElectionFound = politicianDB.map((politician:any)=>{
          politician.image_path = this.storage.ref(politician.image_path).getDownloadURL();
          return politician;
        });
         this.buscar = false;
      }
    })
   
  }

  buscarPorComision(){
    let commissionFilter:any = [];
     this.diputados.map(d=>{
       d.commissions.map(c=>{
         if(c.commission_Id == this.commissionSelected ){
          d.assignment = c;
          commissionFilter = [...commissionFilter , d];
         }
       })
    });
  
    if(commissionFilter.length === 0){
      alert('No se encuentran registros con los criterios de busqueda seleccionados');
      return;
    }
 
    this.politiciansCommissionFound = commissionFilter;
  }




  buscarPorSeccion() {
   
   const districtSelected:any = this.listDistritos.filter((distrito)=>distrito.sections.includes(this.section))
   
      this.politiciansService.getPoliticianByDistrictID(parseInt(districtSelected[0].id_district, 10)).subscribe((politicianDB) => {
      this.politicianDistrictFound = politicianDB[0];
      if (this.politicianDistrictFound == null || this.politicianDistrictFound.name == null) {
        alert("Sin resultados, vuelve a intentarJ");
      } else {
        this.perfilFotoURL = this.storage.ref(this.politicianDistrictFound.image_path).getDownloadURL();
        this.buscar = false;
        this.distBySection = null;
        this.section = null;

      }
    })
  }

  eliminarResultado() {
    this.distritos = this.politiciansService.getDistricts();
    this.cargarDatosTabla();
    this.politiciansCommissionFound = [];
    this.politiciansMunicipalitiesFound = [];
    this.politiciansElectionFound = [];
    this.politicianDistrictFound = {};
    this.buscar = true;
    this.section;
    this.electionSelected = 'MR';
    this.distSelected = 1;
    this.municipalitySelected = 1;
    this.commissionSelected = 1;
  }


}
