import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { BancadaService } from './bancada.service';


import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';


@Component({
  selector: 'bancada',
  templateUrl: './bancada.component.html',
  styleUrls: ['./bancada.component.scss'],
  providers: [BancadaService]
})
export class BancadaComponent implements OnInit {
  mySplit: (string: any, nb: any) => any;
  angular: any;
  sexIndic: any;
  avgAgeIndic: any;
  tipoRepreIndic: any;
  avg_r: any;


  isLoading: boolean;

  constructor(private bancadaService: BancadaService, private rutaActiva: ActivatedRoute, private storage: AngularFireStorage) { }
  dURL: Observable<any>;
  bancada: string;
  bancadaObj: any;
  data: any[];
  diputados: any[];
  politicians: Observable<any[]>;
  sortDataName:false;
  sortDataAssistences:false;
  sortDataCommissionsAssistences:false;
  sortDataFullParticipation:false;
  sortDataInitiativesPresented:false;
  sortDataTransparency:false;
  sortDataInitiativesApproved:false;
  sortDataTotal:false;




  ngAfterContentInit() {
    this.isLoading = false;
  }


  ngOnInit() {
    this.isLoading = true;
    this.bancada = this.rutaActiva.snapshot.params.bancada,
      this.rutaActiva.params.subscribe(
        (params: Params) => {
          this.bancada = params.bancada;
/*           console.log("los datos2", params.bancada);
 */
        }
      );

    this.bancadaService.getBancada(this.bancada).subscribe((bancadaBD) => {
      this.bancadaObj = bancadaBD[0];
      this.dURL = this.storage.ref(this.bancadaObj.image_path).getDownloadURL();
      // this.avg_r = this.bancadaObj['avg_rating'];
      
      this.sexIndic = [
        { "name": "Hombres", "value": parseInt(this.bancadaObj.num_men, 10) },
        { "name": "Mujeres", "value": parseInt(this.bancadaObj.num_women, 10) }
      ];   


      this.avgAgeIndic = [
        { "name": "Edad promedio", "value": parseInt(this.bancadaObj.avg_age, 10) }
      ];

      this.tipoRepreIndic = [
        { "name": "Representación proporcional", "value": parseInt(this.bancadaObj.num_RP, 10) },
        { "name": "Mayoría relativa", "value": parseInt(this.bancadaObj.num_MR, 10) }
      ];

    })

    this.politicians = this.bancadaService.getMembers(this.bancada);
    


    // this.avg_r = this.bancadaObj['avg_rating'];
    this.politicians.forEach((poldata) => {
      this.diputados = poldata;
      let women = 0;
      let men = 0; 
      let mr = 0;
      let rp = 0;
      let avg_r = 0;
      for (let i = 0; i < this.diputados.length; i++) {
        this.diputados[i].image_path = this.storage.ref(this.diputados[i].image_path).getDownloadURL();
        if(this.diputados[i].gender === 'HOMBRE'){
          men = men + 1;
        }else{
          women = women + 1;
        }

        if(this.diputados[i].election === 'RP'){
          rp = rp + 1;
        }else{
          mr = mr + 1;
        }
        avg_r = (Number(avg_r) + Number(this.diputados[i].percentile_Indicators.total))

        this.sexIndic = [
          { "name": "Hombres", "value": men },
          { "name": "Mujeres", "value": women}
        ];  

        this.tipoRepreIndic = [
          { "name": "Representación proporcional", "value": rp },
          { "name": "Mayoría relativa", "value": mr }
        ];
         
        
      }

      this.avg_r = (avg_r / this.diputados.length).toFixed(2);

    });









  }






  loading = false;
  placeholders = [];
  pageSize = 10;
  pageToLoadNext = 1;
  view: any[] = [900, 300];
  colorScheme = {
    domain: ['#20d073', '#f74c6e', '#C7B42C', '#AAAAAA']
  };
  viewTransp: any[] = [200, 300];
  gradient = false;
  showXAxis = true;
  showYAxis = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = 'Population';

  onSelect(event) {
    console.log(event);
  }

  sortDataAss(active , property){
    this.sortDataAssistences = active;
    this.sortDataName = false;
    this.sortDataCommissionsAssistences=false;
    this.sortDataFullParticipation= false;
    const politicianOrderBy = this.sortDataRanking(this.sortDataAssistences,property)
    this.diputados = politicianOrderBy;
  }

  sortDataCommissionAss(active , property){
    this.sortDataCommissionsAssistences= active;
    this.sortDataAssistences = false;
    this.sortDataName = false;
    this.sortDataFullParticipation= false;
    const politicianOrderBy = this.sortDataRanking(this.sortDataCommissionsAssistences,property)
    this.diputados = politicianOrderBy;
  }

  sortDataFullP(active , property){
    this.sortDataFullParticipation= active;
    this.sortDataAssistences = false;
    this.sortDataName = false;
    this.sortDataCommissionsAssistences= false;    
    const politicianOrderBy = this.sortDataRanking(this.sortDataFullParticipation,property)
    this.diputados = politicianOrderBy;
  }

  sortDataInitiativesP(active , property){
    this.sortDataInitiativesPresented= active;
    this.sortDataAssistences = false;
    this.sortDataName = false;
    this.sortDataCommissionsAssistences= false;  
    this.sortDataFullParticipation= false;  
    const politicianOrderBy = this.sortDataRanking(this.sortDataInitiativesPresented,property)
    this.diputados = politicianOrderBy;

  }

  sortDataTrans(active,property){
    this.sortDataTransparency=active;
    this.sortDataInitiativesPresented= false;
    this.sortDataAssistences = false;
    this.sortDataName = false;
    this.sortDataCommissionsAssistences= false;  
    this.sortDataFullParticipation= false;  
    const politicianOrderBy = this.sortDataRanking(this.sortDataTransparency,property)
    this.diputados = politicianOrderBy;
  }

  sortDataInitiativesA(active,property){
    this.sortDataInitiativesApproved = active;
    this.sortDataTransparency=false;
    this.sortDataInitiativesPresented= false;
    this.sortDataAssistences = false;
    this.sortDataName = false;
    this.sortDataCommissionsAssistences= false;  
    this.sortDataFullParticipation= false;  
    const politicianOrderBy = this.sortDataRanking(this.sortDataInitiativesApproved,property)
    this.diputados = politicianOrderBy;
  }

  sortDataT(active,property){
    this.sortDataTotal = active;
    this.sortDataInitiativesApproved = active;
    this.sortDataTransparency=false;
    this.sortDataInitiativesPresented= false;
    this.sortDataAssistences = false;
    this.sortDataName = false;
    this.sortDataCommissionsAssistences= false;  
    this.sortDataFullParticipation= false;  
    const politicianOrderBy = this.sortDataRanking(this.sortDataTotal,property)
    this.diputados = politicianOrderBy;

  }

  sortDataRanking(sortDataProperty , property){
    let direction = sortDataProperty ? 1 : -1;
    let politicianOrderBy = this.diputados.sort((a,b)=>{
      if(a.percentile_Indicators[property] < b.percentile_Indicators[property]){
         return -1 * direction;
      }else if(a.percentile_Indicators[property] > b.percentile_Indicators[property]){
        return 1 * direction;
      }else{
        return 0
      }
    });

    return politicianOrderBy;
  }

  sortData(sortDataProperty  , property){
    let direction = sortDataProperty ? 1 : -1;
    let politicianOrderBy = this.diputados.sort((a,b)=>{
      if(a[property] < b[property]){
         return -1 * direction;
      }else if(a[property] > b[property]){
        return 1 * direction;
      }else{
        return 0
      }
    });

    return politicianOrderBy;
  }

  openurl = function (url) {
    window.open(url, '_blank');   // may alse try $window
  }


}
