import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';



const TOTAL_PAGES = 7;


@Injectable()
export class BancadaService {

  partidoCollection: AngularFirestoreCollection<any>;
  politicians: Observable<any[]>;
  url: string; 
  path: string;

  constructor(private afs: AngularFirestore) {
    this.partidoCollection = this.afs.collection('political-parties', (ref) => ref.orderBy('full_name', 'asc'));
    
  }

  getBancada(bancada: string) {

    return  this.afs.collection('political-parties', (ref) => ref.where('abbreviation','==',bancada).limit(1)).valueChanges();

  }
  getMembers(bancada: string) {

    return this.afs.collection('politicians', (ref) => ref.where('political_party.value','==',bancada).orderBy('percentile_Indicators.total', 'desc')).snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id, ...(data as object) };
        });
      })
    );



  }

  /** 
   * 
   *  ref.child('users').orderByChild('name').equalTo('John Doe').on("value", function(snapshot) {
      console.log(snapshot.val());
      snapshot.forEach(function(data) {
        console.log(data.key);
      });
    });
   * 
   * 
   * 
   * */


}
