import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent } from './pages/home/home.component';
import { DiputadosComponent } from './pages/diputados/diputados.component';
// import { RankingComponent } from './pages/ranking/ranking.component';
import { AlternativeRankingComponent } from './pages/alternative-ranking/alternative-ranking.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { BancadaComponent } from './pages/bancada/bancada.component';
import { UbicaTuDiputadoComponent } from './pages/ubica-tu-diputado/ubica-tu-diputado.component';
import { NewsComponent } from './pages/novedades/news.component';
import { CommissionsComponent } from './pages/commissions/commissions.component';
import { CommissionPoliticiansComponent } from './pages/commission-politicians/commission-politicians.component';


const routes: Routes = [
  { path: '', pathMatch: 'full',  component: HomeComponent, runGuardsAndResolvers: 'always' },
  { path: 'diputados', pathMatch: 'full',  component: DiputadosComponent, runGuardsAndResolvers: 'always' },
  // { path: 'ranking', pathMatch: 'full',  component: RankingComponent, runGuardsAndResolvers: 'always' },
  { path: 'ranking', pathMatch:'full' , component: AlternativeRankingComponent , runGuardsAndResolvers:'always'},
  { path: 'perfil/:id', component: PerfilComponent, runGuardsAndResolvers: 'always' },
  { path: 'bancada/:bancada', component: BancadaComponent, runGuardsAndResolvers: 'always' },
  { path: 'ubica-tu-diputado', component: UbicaTuDiputadoComponent, runGuardsAndResolvers: 'always' },
  { path: 'novedades', pathMatch: 'full',component: NewsComponent, runGuardsAndResolvers: 'always' },
  { path: 'comisiones', pathMatch: 'full',component: CommissionsComponent, runGuardsAndResolvers: 'always' },
  { path: 'comision-diputados/:id', pathMatch: 'full',component: CommissionPoliticiansComponent, runGuardsAndResolvers: 'always' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
