import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Params } from "@angular/router";
import { Observable } from 'rxjs';
import { CommissionPoliticiansService } from './commission-politicians.service';

@Component({
  selector: 'app-commission-politicians',
  templateUrl: './commission-politicians.component.html',
  styleUrls: ['./commission-politicians.component.scss'],
  providers: [CommissionPoliticiansService]
})
export class CommissionPoliticiansComponent implements OnInit {
  politicians: Observable<any[]>;
  commissions: Observable<any[]>;
  constructor(
    private rutaActiva: ActivatedRoute,
    private CommissionPoliticiansService:CommissionPoliticiansService,
    private storage: AngularFireStorage
  ) { }

  idCommission:string = '';
  commissionSelected:any = '';
  diputados:Array<any> = [];
  comisiones:Array<any> = [];

  ngOnInit(): void {
    this.politicians = this.CommissionPoliticiansService.getPolitician();
    this.commissions = this.CommissionPoliticiansService.getCommissions();
    (this.idCommission = this.rutaActiva.snapshot.params.id),
      this.rutaActiva.params.subscribe((params: Params) => {
        this.idCommission = params.id;
      });

    this.cargarDatos();
  }



  cargarDatos(){

    this.commissions.forEach((poldata)=>{
        this.comisiones = poldata;

        const commissionSelected = this.comisiones.filter(c=>c.commission_id === this.idCommission);
        this.commissionSelected = commissionSelected[0]['name'];
    });

     this.politicians.forEach((poldata)=>{
       let politicianArray = poldata;
        
       let diputadosArray:any = [];
       politicianArray.map(d=>{
          d.image_path = this.storage.ref(d.image_path).getDownloadURL();
          const diputadosComision = d.commissions.filter(c=>c.commission_Id === this.idCommission);
          if(diputadosComision.length > 0){
            d.commission = diputadosComision[0].assignment;
            diputadosArray = [...diputadosArray , d];
          }
        });
        this.diputados = diputadosArray
        
     });
  }



}
