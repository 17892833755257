export const municipios = [
  { name: "Acambay de Ruiz Castaneda", value: 1 , districts:[{district_id:14}]},
  { name: "Acolman", value: 2 , districts:[{district_id:39}]},
  { name: "Aculco", value: 3 , districts:[{district_id:14}]},
  { name: "Almoloya de Alquisiras", value: 4 , districts:[{district_id:9}]},
  { name: "Almoloya de Juarez", value: 5 , districts:[{district_id:45}]},
  { name: "Almoloya del Rio", value: 6 , districts:[{district_id:7}]},
  { name: "Amanalco", value: 7 , districts:[{district_id:10}]},
  { name: "Amatepec", value: 8 , districts:[{district_id:9}]},
  { name: "Amecameca", value: 9 , districts:[{district_id:28}]},
  { name: "Apaxco", value: 10, districts:[{district_id:20}]},
  { name: "Atenco", value: 11, districts:[{district_id:23}]},
  { name: "Atizapan de Zaragoza", value: 12, districts:[{district_id:29} , {district_id:16}]},
  { name: "Atizapan", value: 13, districts:[{district_id:4}]},
  { name: "Atlacomulco", value: 14, districts:[{district_id:13}]},
  { name: "Atlautla", value: 15, districts:[{district_id:28}]},
  { name: "Axapusco", value: 16, districts:[{district_id:39}]},
  { name: "Ayapango", value: 17, districts:[{district_id:28}]},
  { name: "Calimaya", value: 18, districts:[{district_id:7}]},
  { name: "Capulhuac", value: 19, districts:[{district_id:4}]},
  { name: "Chalco", value: 20, districts:[{district_id:1}]},
  { name: "Chapa de Mota", value: 21, districts:[{district_id:14}]},
  { name: "Chapultepec", value: 22, districts:[{ district_id:35}]},
  { name: "Chiautla", value: 23, districts:[{district_id:39}]},
  { name: "Chicoloapan", value: 24, districts:[{district_id:5}]},
  { name: "Chiconcuac", value: 25, districts:[{district_id:23}]},
  { name: "Chimalhuacan", value: 26, districts:[{district_id:3} , {district_id:5} , {district_id:31}]},
  { name: "Coacalco de Berriozabal", value: 27, districts:[{district_id:38}]},
  { name: "Coatepec Harinas", value: 28, districts:[{district_id:9}]},
  { name: "Cocotitlan", value: 29, districts:[{district_id:1}]},
  { name: "Coyotepec", value: 30, districts:[{district_id:12}]},
  { name: "Cuautitlan Izcalli", value: 31, districts:[{district_id:26} , {district_id:43}]},
  { name: "Cuautitlan", value: 32, districts:[{district_id:19},{district_id:12}]},
  { name: "Donato Guerra", value: 33, districts:[{district_id:10}] },
  { name: "Ecatepec de Morelos", value: 34, districts:[{district_id:6} , {district_id:8} , {district_id:21}  , {district_id:22} , {district_id:37} , {district_id:42}] },
  { name: "Ecatzingo", value: 35, districts:[{district_id:28}]},
  { name: "El Oro", value: 36, districts:[{district_id:13}]},
  { name: "Huehuetoca", value: 37, districts:[{district_id:20}]},
  { name: "Hueypoxtla", value: 38, districts:[{district_id:20}]},
  { name: "Huixquilucan", value: 39, districts:[{district_id:17}]},
  { name: "Isidro Fabela", value: 40, districts:[{district_id:17}]},
  { name: "Ixtapaluca", value: 41, districts:[{district_id:28} , {district_id:40}]},
  { name: "Ixtapan de la Sal", value: 42, districts:[{district_id:9}]},
  { name: "Ixtapan del Oro", value: 43, districts:[{district_id:10}]},
  { name: "Ixtlahuaca", value: 44, districts:[{district_id:15}]},
  { name: "Jaltenco", value: 45, districts:[{district_id:12}]},
  { name: "Jilotepec", value: 46, districts:[{district_id:14}]},
  { name: "Jilotzingo", value: 47, districts:[{district_id:17}]},
  { name: "Jiquipilco", value: 48, districts:[{district_id:15}]},
  { name: "Jocotitlan", value: 49, districts:[{district_id:13}]},
  { name: "Joquicingo", value: 50, districts:[{district_id:7}]},
  { name: "Juchitepec", value: 51, districts:[{district_id:28}]},
  { name: "La Paz", value: 52, districts:[{district_id:31}]},
  { name: "Lerma", value: 53, districts:[{district_id:4}] },
  { name: "Luvianos", value: 54, districts:[{district_id:9}]},
  { name: "Malinalco", value: 55, districts:[{district_id:7}]},
  { name: "Melchor Ocampo", value: 56, districts:[{district_id:12}]},
  { name: "Metepec", value: 57, districts:[{district_id:35}]},
  { name: "Mexicaltzingo", value: 58, districts:[{district_id:35}]},
  { name: "Morelos", value: 59, districts:[{district_id:14}]},
  { name: "Naucalpan de Juarez", value: 60, districts:[{district_id:29} , {district_id:30} , {district_id:32}]},
  { name: "Nextlalpan", value: 61, districts:[{district_id:12}]},
  { name: "Nezahualcoyotl", value: 62, districts:[{district_id:24} , {district_id:25} ,{district_id:41}]},
  { name: "Nicolas Romero", value: 63, districts:[{district_id:44}]},
  { name: "Nopaltepec", value: 64, districts:[{district_id:39}]},
  { name: "Ocoyoacac", value: 65, districts:[{district_id:4}]},
  { name: "Ocuilan", value: 66, districts:[{district_id:7}]},
  { name: "Otumba", value: 67, districts:[{district_id:39}]},
  { name: "Otzoloapan", value: 68, districts:[{district_id:10}]},
  { name: "Otzolotepec", value: 69, districts:[{district_id:34},{district_id:45}]},
  { name: "Ozumba", value: 70, districts:[{district_id:28}]},
  { name: "Papalotla", value: 71, districts:[{district_id:39}]},
  { name: "Polotitlan", value: 72, districts:[{district_id:14}]},
  { name: "Rayon", value: 73, districts:[{district_id:7}]},
  { name: "San Antonio la Isla", value: 74, districts:[{district_id:7}]},
  { name: "San Felipe del Progreso", value: 75, districts:[{district_id:15}]},
  { name: "San Jose del Rincon", value: 76, districts:[{district_id:13}]},
  { name: "San Martin de las Piramides", value: 77, districts:[{district_id:39}]},
  { name: "San Mateo Atenco", value: 78, districts:[{district_id:35}]},
  { name: "San Simon de Guerrero", value: 79, districts:[{district_id:10}]},
  { name: "Santo Tomas", value: 80, districts:[{district_id:10}]},
  { name: "Soyaniquilpan de Juarez", value: 81, districts:[{district_id:14}]},
  { name: "Sultepec", value: 82, districts:[{district_id:9}]},
  { name: "Tecamac", value: 83, districts:[{district_id:33}]},
  { name: "Tejupilco", value: 84, districts:[{district_id:9}]},
  { name: "Temamatla", value: 85, districts:[{district_id:1}] },
  { name: "Temascalapa", value: 86, districts:[{district_id:39}]},
  { name: "Temascalcingo", value: 87, districts:[{district_id:13}]},
  { name: "Temascaltepec", value: 88, districts:[{district_id:10}]},
  { name: "Temoaya", value: 89, districts:[{district_id:45}]},
  { name: "Tenancingo", value: 90, districts:[{district_id:7}]},
  { name: "Tenango del Aire", value: 91, districts:[{district_id:28}]},
  { name: "Tenango del Valle", value: 92, districts:[{district_id:7}]},
  { name: "Teoloyucan", value: 93, districts:[{district_id:12}]},
  { name: "Teotihuacan", value: 94, districts:[{district_id:39}]},
  { name: "Tepetlaoxtoc", value: 95, districts:[{district_id:39}]},
  { name: "Tepetlixpa", value: 96, districts:[{district_id:28}]},
  { name: "Tepotzotlan", value: 97, districts:[{district_id:12}]},
  { name: "Tequixquiac", value: 98, districts:[{district_id:20}]},
  { name: "Texcaltitlan", value: 99, districts:[{district_id:10}]},
  { name: "Texcalyacac", value: 100, districts:[{district_id:4}]},
  { name: "Texcoco", value: 101, districts:[{district_id:23}]},
  { name: "Tezoyuca", value: 102, districts:[{district_id:23}]},
  { name: "Tianguistenco", value: 103, districts:[{district_id:4}]},
  { name: "Timilpan", value: 104, districts:[{district_id:14}]},
  { name: "Tlalmanalco", value: 105, districts:[{district_id:28}]},
  { name: "Tlalnepantla de Baz", value: 106, districts:[{district_id:18},{district_id:26},{district_id:37}]},
  { name: "Tlatlaya", value: 107, districts:[{district_id:9}]},
  { name: "Toluca", value: 108, districts:[{district_id:2} , {district_id:36} , {district_id:34} , {district_id:45}] },
  { name: "Tonanitla", value: 109, districts:[{district_id:12}]},
  { name: "Tonatico", value: 110, districts:[{district_id:9}]},
  { name: "Tultepec", value: 111, districts:[{district_id:19}]},
  { name: "Tultitlan", value: 112, districts:[{district_id:11} , {district_id:19} , {district_id:38}]},
  { name: "Valle de Bravo", value: 113, districts:[{district_id:10}]},
  { name: "Valle de Chalco Solidaridad", value: 114, districts:[{district_id:27}]},
  { name: "Villa Guerrero", value: 115, districts:[{district_id:9}]},
  { name: "Villa Victoria", value: 116, districts:[{district_id:10}]},
  { name: "Villa de Allende", value: 117, districts:[{district_id:10}]},
  { name: "Villa del Carbon", value: 118, districts:[{district_id:14}]},
  { name: "Xalatlaco", value: 119, districts:[{district_id:4}]},
  { name: "Xonacatlan", value: 120, districts:[{district_id:17}]},
  { name: "Zacazonapan", value: 121, districts:[{district_id:10}]},
  { name: "Zacualpan", value: 122, districts:[{district_id:9}]},
  { name: "Zinacantepec", value: 123, districts:[{district_id:36}]},
  { name: "Zumpahuacan", value: 124, districts:[{district_id:7}]},
  { name: "Zumpango", value: 125, districts:[{district_id:20}]},
];
