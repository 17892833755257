import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

export class CommissionPoliticiansService {

    politicianCollection: AngularFirestoreCollection<any>;
    commissionCollection: AngularFirestoreCollection<any>;

    constructor(private afs: AngularFirestore) {
        this.politicianCollection = this.afs.collection('politicians', (ref) => ref.orderBy('name', 'asc'));
        this.commissionCollection = this.afs.collection('commissions',(ref)=>ref.orderBy('commission_id','asc'));
    }

    getPolitician(): Observable<any[]> {
        // ['added', 'modified', 'removed']
        return this.politicianCollection.snapshotChanges().pipe(
          map((actions) => {
            return actions.map((a) => {
              const data = a.payload.doc.data();
              return { id: a.payload.doc.id, ...data };
            });
          })
        );
      }

      getCommissions():Observable<any[]>{
        return this.commissionCollection.snapshotChanges().pipe(
          map((actions)=>{
            return actions.map((a)=>{
              const data = a.payload.doc.data();
              return { id: a.payload.doc.id , ...data }
            })
    
          })
        )
      }
    

}