import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeService]
})
export class HomeComponent implements OnInit {


  downloadURL: Observable<any>;
  politicosBD: Observable<any[]>;
  partidosBD: Observable<any[]>;
  listDiputados: any[];

  constructor(private politiciansService: HomeService, private storage: AngularFireStorage) { }

  ngOnInit() {
    this.politicosBD = this.politiciansService.getPolitician();
    this.partidosBD = this.politiciansService.getPartidos();
    this.cargarDatosTabla();

  }

  diputados: any[];
  total_diputados: number;
  partidos: any[];
  total_partidos: number;
  id: any[];
  urlFilter: string;
  dURL: Observable<any>;

  cargarDatosTabla() {



    this.partidos = [];


    this.politicosBD.forEach((poldata) => {
      this.diputados = poldata;
      this.total_diputados = this.diputados.length;
      // console.log(JSON.stringify(poldata));
      this.diputados.length = this.diputados.length > 5 ? 5 : this.diputados.length;
      
      for (let i = 0; i < this.diputados.length; i++) {
        this.diputados[i].image_path = this.storage.ref(this.diputados[i].image_path.toString()).getDownloadURL();
      }

    });


    this.partidosBD.forEach((poldata) => {
      this.partidos = poldata;
      this.total_partidos = this.partidos.length;
      for (let i = 0; i < this.partidos.length; i++) {
        // console.log("PARTIDOS #"+i+" "+JSON.stringify(this.partidos[i].name)+" "+JSON.stringify(this.partidos[i].path_logo));
        this.partidos[i].image_path = this.storage.ref(this.partidos[i].image_path).getDownloadURL();
      }
    });



  }

  openurl = function (url) {
    window.open(url, '_blank');   // may alse try $window
  }

}