import { Component, OnInit } from "@angular/core";
import { DiputadosService } from "./diputados.service";
import { Observable } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { municipios } from "../ubica-tu-diputado/municipios";

@Component({
  selector: "diputados",
  templateUrl: "./diputados.component.html",
  styleUrls: ["./diputados.component.scss"],
  providers: [DiputadosService],
})
export class DiputadosComponent implements OnInit {
  politicians: Observable<any[]>;
  bancadas: Observable<any[]>;
  commission: Observable<any[]>;
  districts: Observable<any[]>;
  paramFilterData: string;
  listMunicipalities: [];
  buttonDisabled: boolean;

  constructor(
    private modalService: NgbModal,
    private diputadosService: DiputadosService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    this.politicians = this.diputadosService.getPolitician();
    this.buttonDisabled = false;
    this.bancadas = this.diputadosService.getBancadas();
    this.commission = this.diputadosService.getCommissions();
    this.districts = this.diputadosService.getDistricts();

    this.cargarDatosTabla();
    this.cargarDataSelectBancada();
    this.cargarDataComisiones();
    this.cargarDataDistritos();
  }

  diputados: any[];
  diputadosRes: any[];
  bancadasList: any[];
  commissionsList: any[];
  districtsList: any[];

  /** se guardan estados anteriores de filtrado en caso de que eliminen un filtro */
  politicalPartyFilterStatus:boolean = false;
  averagePerPoliticalParty:string;
  politicalPartyFilter:string = '';

  genderFilterStatus:boolean = false;
  averagePerGender:string;
  politicianPerGenderFilter:string = '';

  commissionFilterStatus:boolean = false;
  averagePerCommission:string;
  politicianPerCommissionFilter:string = '';

  typeElectionFilterStatus:boolean = false;
  averagePerTypeElection:string;
  politicianPerElectionFilter:string= '';

  districtFilterStatus:boolean = false;
  averagePerDistrict:string;
  politicianPerDisctrictFilter:string = '';
  nameDistrictFilter:string='';

  municipalityFilterStatus:boolean = false;
  averagePerMunicipality:string;
  politicianPerMunicipalityFilter:string = '';
  nameMunicipalityFilter:string='';

  sectionFilterStatus:boolean = false;
  averagePerSection:string;
  politicianPerSectionFilter:string = '';

  cargarDatosTabla() {
    this.diputados = [];
    this.listMunicipalities = municipios;
    this.politicians.forEach((poldata) => {
      this.diputados = poldata;
      this.diputadosRes = poldata;
      for (let i = 0; i < this.diputados.length; i++) {
        this.diputados[i].image_path = this.storage
          .ref(this.diputados[i].image_path)
          .getDownloadURL();
      }
    });
  }

  cargarDataSelectBancada() {
    this.bancadas.forEach((poldata) => {
      this.bancadasList = poldata;
    });
  }

  cargarDataComisiones() {
    this.commission.forEach((poldata) => {
      this.commissionsList = poldata;
    });
  }

  cargarDataDistritos() {
    this.districts.forEach((poldata) => {
      this.districtsList = poldata;
    });
  }

  reloadFilter(){
    this.diputados = this.diputadosRes;
    if(this.politicalPartyFilterStatus){
      this.filterPerPoliticalParty();
    }

    if(this.genderFilterStatus){
      this.filterPerGender();
    }

    if(this.commissionFilterStatus){
      this.filterPerCommission();
    }

    if(this.typeElectionFilterStatus){
      this.filterPerTypeElection();
    }

    if(this.districtFilterStatus){
      this.filterPerDistrict();
    }

    if(this.municipalityFilterStatus){
      this.filterPerMunicipality();
    }

    if(this.sectionFilterStatus){
      this.filterBySection();
    }
  }

  startFilter(){
    this.modalService.dismissAll();

    if(this.diputados.length <= 1){
      alert('No se pueden aplicar más filtros');
      return;
    }
    
    if(this.paramFilterData == 'Bancada'){
      this.filterPerPoliticalParty();
      this.paramFilterData = '';
      return;

    }else if(this.paramFilterData == 'Género'){
      this.filterPerGender();
      this.paramFilterData = '';
      return;
    }else if(this.paramFilterData == 'Comisión'){
      this.filterPerCommission();
      this.paramFilterData = '';
      return;
    }else if(this.paramFilterData == 'Tipo de Representación'){
      this.filterPerTypeElection();
      this.paramFilterData = '';
      return;
    }else if(this.paramFilterData == 'Distrito'){
      this.filterPerDistrict();
      this.paramFilterData = '';
    }else if(this.paramFilterData == 'Municipio'){
      this.filterPerMunicipality();
      this.paramFilterData = '';
    }else{
      this.filterBySection();
    }


  }

  filterPerPoliticalParty(){
    const data = this.filterDataPerParameter('political_party','value',this.politicalPartyFilter);
    if(data.length === 0){
      alert('No se encuentran registros con los critrerios de busqueda seleccionados');
      return;
    }
    let average = data.reduce((acc , {percentile_Indicators})=> acc + Number(percentile_Indicators.total) , 0)
    this.averagePerPoliticalParty = (average / data.length).toFixed(2);
    this.politicalPartyFilterStatus = true;
    this.diputados = data;
  }


  filterPerGender(){
    const data = this.filterDataPerParameter('gender' , '' , this.politicianPerGenderFilter );
    if(data.length === 0){
      alert('No se encuentran registros con los criterios de busqueda seleccionados');
      return;
    }
    let average = data.reduce((acc , {percentile_Indicators})=> acc + Number(percentile_Indicators.total) , 0)
    this.averagePerGender = (average / data.length).toFixed(2); 
    this.genderFilterStatus = true;
    this.diputados = data;
  }

  filterPerCommission(){
    let commissionFilter:any = [];
     this.diputados.map(d=>{
       

       d.commissions.map(c=>{
         if(c.commission == this.politicianPerCommissionFilter ){
          d.assignment = c;
          commissionFilter = [...commissionFilter , d];
         }
       })
    });  
    if(commissionFilter.length === 0){
      alert('No se encuentran registros con los criterios de busqueda seleccionados');
      return;
    }
    let average = commissionFilter.reduce((acc , {percentile_Indicators})=> acc + Number(percentile_Indicators.total) , 0)
    this.averagePerCommission = (average / commissionFilter.length).toFixed(2);
    this.commissionFilterStatus = true;
    this.diputados = commissionFilter;
  }

  filterPerTypeElection(){
    const data = this.filterDataPerParameter('election','',this.politicianPerElectionFilter);
    if(data.length === 0){
      alert('No se encuentran registros con los criterios de busqueda seleccionados');
      return;
    }
    let average = data.reduce((acc , {percentile_Indicators})=> acc + Number(percentile_Indicators.total) , 0)
    this.averagePerTypeElection = (average / data.length).toFixed(2); 
    this.typeElectionFilterStatus = true;
    this.diputados = data;
  }

  filterPerDistrict(){
    const data = this.filterDataPerParameter("district","value",this.politicianPerDisctrictFilter);
    if(data.length == 0){
      alert('No se encuentran registros con los criterios de busqueda seleccionados');
      return;
    }
    let average = data.reduce((acc , {percentile_Indicators})=> acc + Number(percentile_Indicators.total) , 0)
    this.averagePerDistrict = (average / data.length).toFixed(2);      
    this.districtFilterStatus = true;
    this.nameDistrictFilter = data[0].district.label;
    this.diputados = data;
  }

  filterPerMunicipality(){
    const municipio = this.listMunicipalities.filter((municipio:any)=>municipio.value == this.politicianPerMunicipalityFilter );
    const { name, district_id , districts }:any = municipio[0];
    let listPoliticians = [];
    districts.forEach(element => { 
      const dip = this.diputados.filter(diputado => diputado.district.value == element.district_id); 
      listPoliticians = [...listPoliticians , dip[0]]
    });   
   
     if(listPoliticians.length == 0){
       alert('No se encuentran registros con los criterios de busqueda seleccionados');
       return;
     }


    let average = listPoliticians.reduce((acc , {percentile_Indicators})=> acc + Number(percentile_Indicators.total) , 0)
    this.averagePerMunicipality = (average / listPoliticians.length).toFixed(2); 
    this.politicianPerMunicipalityFilter = district_id;
    this.nameMunicipalityFilter = name;
    this.municipalityFilterStatus = true;
    this.diputados = listPoliticians;
  }

  filterBySection(){
    const data = this.filterDataPerSección(this.politicianPerSectionFilter,"district","value");
    if(!data.length){
      alert('No se encuentran registros con los criterios de busqueda seleccionados');
      return;
    }
    let average = data.reduce((acc , {percentile_Indicators})=> acc + Number(percentile_Indicators.total) , 0)
    this.averagePerSection = (average / data.length).toFixed(2);
    this.sectionFilterStatus = true;
    this.diputados = data;
  }

  filterDataPerParameter(param, param2, data) {
    let filterData;
    if (param2 !== "") {
      filterData = this.diputados.filter(
        (diputado) => diputado[`${param}`][`${param2}`] == data
      );
    } else {
      filterData = this.diputados.filter(
        (diputado) => diputado[`${param}`] == data
      );
    }
    return filterData;
  }

  filterDataPerSección(section, param, param2) {
    const district: any = this.districtsList.filter((distrito) =>
      distrito.sections.includes(section)
    );
    const politician = this.diputados.filter(
      (diputado) => diputado[`${param}`][`${param2}`] == district[0].id_district
    );
    return politician;
  }

  openurl = function (url) {
    window.open(url, "_blank"); // may alse try $window
  };

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  selectFilter(param) {
    this.paramFilterData = param;
  }

  selectOptionBancada(value) {
    this.politicalPartyFilter = value;
  }
  selectOptionGenero(value) {
    this.politicianPerGenderFilter = value;
  }

  selectOptionComision(value) {
    this.politicianPerCommissionFilter = value;
  }

  selectOptionRepresentacion(value) {
    this.politicianPerElectionFilter = value;
  }

  selectOptionDistrito(value) {
    this.politicianPerDisctrictFilter = value;
  }

  selectOptionMunicipio(value) {
    this.politicianPerMunicipalityFilter = value;
  }

  
   cancelFilterGender(){
    this.genderFilterStatus = false;
    this.averagePerGender= '';
    this.politicianPerGenderFilter= '';
    this.reloadFilter();
   }

  cancelFilterBancada(){
    this.politicalPartyFilterStatus = false;
    this.averagePerPoliticalParty = '';
    this.politicalPartyFilter = '';
    this.reloadFilter();
    
  }

  cancelFilterCommission(){
    this.commissionFilterStatus = false;
    this.averagePerCommission = '';
    this.politicianPerCommissionFilter = '';
    this.reloadFilter();
  }

  cancelFilterElection(){
    this.typeElectionFilterStatus = false;
    this.averagePerTypeElection = '';
    this.politicianPerElectionFilter= '';
    this.reloadFilter();
  }

  cancelFilterDistrict(){
    this.districtFilterStatus = false;
    this.averagePerDistrict = '';
    this.politicianPerDisctrictFilter = '';
    this.nameDistrictFilter ='';
    this.reloadFilter();
  }

  cancelFilterMunicipality(){
    this.municipalityFilterStatus = false;
    this.averagePerMunicipality = '';
    this.politicianPerMunicipalityFilter = '';
    this.nameMunicipalityFilter='';
    this.reloadFilter();
  }

  cancelFilterSection(){
    this.sectionFilterStatus = false;
    this.averagePerSection='';
    this.politicianPerSectionFilter = '';
    this.reloadFilter();
  }

}
