import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsletterService } from './newsletter.service'; 
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  providers:[NewsletterService , NgbAlertConfig ]
})

export class NewsletterComponent implements OnInit {
  @Input() public alerts: Array<string> = [];

  newsletterUsers: Observable<any[]>;
  message:boolean = false;

  constructor( private newsletterService:NewsletterService , alertConfig: NgbAlertConfig) { 
    alertConfig.type = 'success';
    alertConfig.dismissible = false;

  }
 
  ngOnInit(): void {
  }

  user = {
    name:'',
    email:''
  }

  saveUser(){
    if(this.user.name === ''){
      alert('El campo nombre es obigatorio');
      return;
    }

    if(this.user.email === ''){
      alert('El campo correo es obigatorio');
      return;
    }
    this.newsletterService.saveNewsletterData(this.user).then(()=>{
      this.message = true;
      this.user = {
        name: '',
        email:''
      }
    }).catch(err=>{
       console.log(err);
    })
  }

}
