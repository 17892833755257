import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';


const TOTAL_PAGES = 7;


@Injectable()
export class PerfilService {

  politicianCollection: AngularFirestoreCollection<any>;
  partidoCollection: AngularFirestoreCollection<any>;
  noteDocument: AngularFirestoreDocument<any>;

  politicians: Observable<any[]>;
  politicalParty: Observable<any[]>;
  url: string;
  path: string;

  constructor(private afs: AngularFirestore) {
    this.politicianCollection = this.afs.collection('politicians', (ref) => ref.orderBy('name', 'asc'));
    this.partidoCollection = this.afs.collection('political-parties', (ref) => ref.orderBy('name', 'asc'));

  }

  createPolitician(polObj: Object) {
    return this.politicianCollection.add(polObj);
  }

  getPolitician(): Observable<any[]> {
    // ['added', 'modified', 'removed']
    return this.politicianCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id, ...data };
        });
      })
    );
  }

  getPartidos(bancada: string) {

    return  this.afs.collection('political-parties', (ref) => ref.where('abbreviation','==',bancada).limit(1)).valueChanges();

  }


  getPolitico(id: string) {
    return this.afs.collection('politicians').doc(id).snapshotChanges();
  }


  //Get data by ID->Politicians
  getPolData(id: string) {
    return this.afs.doc<any>(`politicians/${id}`)
  }

  deletePolitician(id: string) {
    return this.getPolData(id).delete();
  }

  updatePolitician(id: string, data: any) {
    return this.getPolData(id).update(data);
  }

  updateUser(userKey, value) {
    return this.getPolData(userKey).set(value);
  }

  getURL(url: string) {
    return this.url = url;
  }

  setURL() {
    return this.url;
  }

  setPath(path: string) {
    return this.path = path;
  }

  getPath() {
    return this.path;
  }

  loadPol(page: number, pageSize: number): Observable<any[]> {
    const startIndex = ((page - 1) % TOTAL_PAGES) * pageSize;


    // return this.http
    //   .get<NewsPost[]>('assets/data/news.json')
    //   .pipe(
    //     map(news => news.splice(startIndex, pageSize)),
    //     delay(1500),
    //   );

    return this.politicianCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id, ...data };
        }).splice(startIndex, pageSize);
      }), delay(1500),
    );
  }

  percentileAssistence(data: any, name: any, p: any) {

    //console.log(name + " >>>PERCENTIL ASISTENCIA<<< " + p)
    var inferior = 0;
    var superior = 0;
    var res: number;
    // var N = data.length;

    // data.sort(function (a, b) {
    //   return b.indicadores_integrales['asistencias'] - a.indicadores_integrales['asistencias'];
    // });
    if (p == 0) {
      return res = 0;
    }
    for (let i = 0; i < data.length; i++) {
      // console.log("ASISTENCIA ORDENADA " + JSON.stringify(data[i].indicadores['asistencias']));

      // console.log("*****ASISTENCIAS**** " + i);
      if (data[i].indicadores_integrales['asistencias'] < p) {
        //console.log("ASISTENCIA ORDENADA " + data[i].name + "  " + data[i].indicadores_integrales['asistencias'] + " < " + p);
        inferior += 1;

      } else if (data[i].indicadores_integrales['asistencias'] > p) {
        //console.log(data[i].asistencia + " > " + p);
        superior += 1;
      }
    }
    return res = inferior / (inferior + superior);
    // console.log(">>>NUMEROS SUPERIORES " + superior);
    // console.log("<<<NUMEROS INFERIORES " + inferior);
    console.log("***VALOR PERCENTIL AS*** " + res);
    return res;
  }

  percentileComission(data: any, name: any, p: any) {

    //console.log(name + " >>>PERCENTIL COMISION<<< " + p)
    var inferior = 0;
    var superior = 0;
    var res: number;
    // var N = data.length;

    // data.sort(function (a, b) {
    //   return b.indicadores_integrales['comisiones'] - a.indicadores_integrales['comisiones'];
    // });
    if (p == 0) {
      return res = 0;
    }
    for (let i = 0; i < data.length; i++) {
      // console.log("ASISTENCIA ORDENADA " + JSON.stringify(data[i].indicadores['asistencias']));

      // console.log("*****ASISTENCIAS**** " + i);
      if (data[i].indicadores_integrales['comisiones'] < p) {
        //console.log("ASISTENCIA ORDENADA " + data[i].name + "  " + data[i].indicadores_integrales['asistencias'] + " < " + p);
        inferior += 1;

      } else if (data[i].indicadores_integrales['comisiones'] > p) {
        //console.log(data[i].asistencia + " > " + p);
        superior += 1;
      }
    }
    return res = inferior / (inferior + superior);
    // console.log(">>>NUMEROS SUPERIORES " + superior);
    // console.log("<<<NUMEROS INFERIORES " + inferior);
    console.log("***VALOR PERCENTIL CO*** " + res);
    return res;
  }

  percentileIniciativas(data: any, name: any, p: any) {

    // console.log(name + " >>>PERCENTIL INICIATIVAS<<< " + p)
    var inferior = 0;
    var superior = 0;
    var res: number;
    // var N = data.length;

    // data.sort(function (a, b) {
    //   return b.indicadores_integrales['comisiones'] - a.indicadores_integrales['comisiones'];
    // });
    if (p == 0) {
      return res = 0;
    }
    for (let i = 0; i < data.length; i++) {
      // console.log("ASISTENCIA ORDENADA " + JSON.stringify(data[i].indicadores['asistencias']));

      // console.log("*****ASISTENCIAS**** " + i);
      if (data[i].indicadores_integrales['iniciativas'] < p) {
        //console.log("ASISTENCIA ORDENADA " + data[i].name + "  " + data[i].indicadores_integrales['asistencias'] + " < " + p);
        inferior += 1;

      } else if (data[i].indicadores_integrales['iniciativas'] > p) {
        //console.log(data[i].asistencia + " > " + p);
        superior += 1;
      }
    }
    return res = inferior / (inferior + superior);
    // console.log(">>>NUMEROS SUPERIORES " + superior);
    // console.log("<<<NUMEROS INFERIORES " + inferior);
    console.log("***VALOR PERCENTIL INI*** " + res);
    return res;
  }

  comunicacionSocial(data: any, name: any, p: any) {

    console.log(name + " >>>PERCENTIL TRANSPARENCIA<<< " + p)
    var inferior = 0;
    var superior = 0;
    var res: number;
    // var N = data.length;

    // data.sort(function (a, b) {
    //   return b.indicadores_integrales['comisiones'] - a.indicadores_integrales['comisiones'];
    // });
    if (p == 0) {
      return res = 0;
    }
    for (let i = 0; i < data.length; i++) {
      // console.log("ASISTENCIA ORDENADA " + JSON.stringify(data[i].indicadores['asistencias']));

      // console.log("*****ASISTENCIAS**** " + i);
      if (data[i].indicadores_integrales['tbyt'] < p) {
        //console.log("ASISTENCIA ORDENADA " + data[i].name + "  " + data[i].indicadores_integrales['asistencias'] + " < " + p);
        inferior += 1;

      } else if (data[i].indicadores_integrales['tbyt'] > p) {
        //console.log(data[i].asistencia + " > " + p);
        superior += 1;
      }
    }
    return res = inferior / (inferior + superior);
    // console.log(">>>NUMEROS SUPERIORES " + superior);
    // console.log("<<<NUMEROS INFERIORES " + inferior);
    console.log("***VALOR PERCENTIL INI*** " + res);
    return res;
  }


  assistanceToSessions(na: number, ns: number) {
    var res: number;
    if (na == 0) {
      return res = 0;
    } else {
      return res = (na / ns);
    }
  }

  assistanceToComission(na: number, nsc: number) {
    var res: number;
    if (na == 0) {
      return res = 0;
    } else {
      return res = (na / nsc);
    }
  }

  iniciatives(ni: number) {
    var res: number;
    res = ni;
    return res;
  }

  declaracionPatrimonial(ni: string) {
    var res: number;
    if (ni == "Si") {
      return res = 1;
    } else {
      return res = 0;
    }
  }

  sumIndicadores(v1, v2, v3, v4, v5) {
    var res = v1 + v2 + v3 + v4 + v5;
    console.log("RESULTADOS " + res);
    return res;

  }

}
