import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NewsletterService {

    newsletterCollection:AngularFirestoreCollection<any>;

    constructor(private afs:AngularFirestore){
     this.newsletterCollection = this.afs.collection('newslatter-users',(ref)=>ref.orderBy('name', 'asc'));
    }

    saveNewsletterData(user){
        return this.newsletterCollection.add(user);
    }
}