import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';




@Injectable()
export class DiputadosService {

  politicianCollection: AngularFirestoreCollection<any>;
  bancadaCollection: AngularFirestoreCollection<any>;
  commissionCollection:AngularFirestoreCollection<any>;
  districtsCollection:AngularFirestoreCollection<any>;
  politicians: Observable<any[]>;
  url: string;
  path: string;

  constructor(private afs: AngularFirestore) {
    this.politicianCollection = this.afs.collection('politicians', (ref) => ref.orderBy('name', 'asc'))
    this.bancadaCollection = this.afs.collection('political-parties', (ref) => ref.orderBy('abbreviation', 'asc'));
    this.commissionCollection = this.afs.collection('commissions',(ref)=>ref.orderBy('name','asc'));
    this.districtsCollection = this.afs.collection('districts',(ref)=>ref.orderBy('id_district','asc'));
  
  }

 
  getPolitician(): Observable<any[]> {
    // ['added', 'modified', 'removed']
    return this.politicianCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id, ...data };
        });
      })
    );
  }

  getBancadas():Observable<any[]>{
    return this.bancadaCollection.snapshotChanges().pipe(
      map((actions)=>{
        return actions.map((a)=>{
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id , ...data }
        })

      })
    )
  }

  getCommissions():Observable<any[]>{
    return this.commissionCollection.snapshotChanges().pipe(
      map((actions)=>{
        return actions.map((a)=>{
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id , ...data }
        })

      })
    )
  }

  getDistricts():Observable<any[]>{
    return this.districtsCollection.snapshotChanges().pipe(
      map((actions)=>{
        return actions.map((a)=>{
          const data = a.payload.doc.data();
          return { id: a.payload.doc.id , ...data }
        })

      })
    )
  }


 

  getURL(url: string) {
    return this.url = url;
  }

  setURL() {
    return this.url;
  }

  setPath(path: string) {
    return this.path = path;
  }

  getPath() {
    return this.path;
    }

}
