import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { NewsService } from './news.service';

@Component({
  selector: 'news-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './news-content.component.html',
  styleUrls: ['./news-content.component.scss']
})

export class NewsContentComponent implements OnInit {

  @Input() news: any;
  randomItem: string;
  title_up: string;
  link_up: string;
  content_up: string;

  constructor(private newsService: NewsService) { }

  ngOnInit() {
    var myArray = [
      "success",
      "info",
      "danger",
      "warning"
    ];

    this.randomItem = myArray[Math.floor(Math.random() * myArray.length)];

    // console.log(JSON.stringify(this.news));
  }

  addViews(val: number) {
    if (this.news.id) {
      this.newsService.updateNews(this.news.id, { views: val + 1 });
    } else {
      console.error('Note missing ID!');
    }
  }

}
