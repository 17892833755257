import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsService } from './news.service';

@Component({
  selector: 'news-add',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit {

  news: Observable<any[]>;
  title: string;
  content: string;
  link: string;
  isDisable: boolean;
  newsArray: any[];

  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
    this.news = this.newsService.getData();


    this.news.subscribe((a)=>{
      this.newsArray = a;
    })

  }

}
