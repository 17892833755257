import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import { PerfilService } from "./perfil.service";

import { Observable } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";

@Component({
  selector: "perfil",
  templateUrl: "./perfil.component.html",
  styleUrls: ["./perfil.component.scss"],
  providers: [PerfilService],
})
export class PerfilComponent implements OnInit {
  mySplit: (string: any, nb: any) => any;
  angular: any;

  isLoading: boolean;

  constructor(
    private perfilService: PerfilService,
    private rutaActiva: ActivatedRoute,
    private storage: AngularFireStorage
  ) {}
  perfilFotoURL: Observable<any>;
  radiografiaURL: Observable<any>;
  Bancadas: Observable<any>;
  idPolitician: string;
  politician: any;
  single: any;
  //datos de grafica de asistencia
  comisionsIndic: any;
  iniciativasIndic: any;
  transparenciaIndic: any;
  socialIndic: any;
  partidosBD: Observable<any[]>;
  data: any[];
  diputados: any[];
  senadores: any[];
  partidos: any[];
  partidosPoliticos: any[];
  BancadaURL:Observable<any>;

  ngOnInit() {
    this.isLoading = true;
    (this.idPolitician = this.rutaActiva.snapshot.params.id),
      this.rutaActiva.params.subscribe((params: Params) => {
        this.idPolitician = params.id;
      });
    //this.politician = this.politiciansService.getPolitico(this.idPolitician);
    this.perfilService.getPolitico(this.idPolitician).subscribe((pol) => {
      let p: any = pol.payload.data();
      p.commissions = p.commissions.sort((a , b) =>{
        if (a.assignment > b.assignment) {
          return 1;
        }
        if (a.assignment < b.assignment) {
          return -1;
        }
        return 0;
      });
      p.percentile_Indicators.total = Math.round(p.percentile_Indicators.total);
      p.percentile_Indicators.assistances = Math.round(
        p.percentile_Indicators.assistances
      );
      this.politician = p;
      this.perfilService.getPartidos(p.political_party.value).subscribe((pol:any)=>{
        this.BancadaURL = this.storage.ref(pol[0].image_path).getDownloadURL();
        
      })


      this.mySplit = function (string, nb) {
        var array = string.split("-");
        return array[nb];
      };

      this.single = [
        {
          name: "Asistencias",
          value: this.politician.percentile_Indicators["assistances"],
        },
      ];

      this.comisionsIndic = [
        {
          name: "Asistencias en comisiones",
          value: this.politician.indicators["politician_assistance_commission"],
        },
        // {
        //   "name": "Faltas en comisiones",
        //   "value": this.politician.indicators['total_comisiones'] - this.politician.indicadores['comisiones']
        // }
      ];

      this.iniciativasIndic = [
        {
          name: "Iniciativas",
          value: this.politician.indicators["initiatives_presented_politician"],
        },
      ];

      this.transparenciaIndic = [
        {
          name: "Presentó 3 de 3",
          value: this.politician.percentile_Indicators["transparency"] * 100,
        },
      ];

      this.socialIndic = [
        {
          name: "Facebook",
          value: parseInt(this.politician.no_followers_facebook, 10),
        },
        {
          name: "Instagram",
          value: parseInt(this.politician.no_followers_instagram, 10),
        },
        {
          name: "Twitter",
          value: parseInt(this.politician.no_followers_twitter, 10),
        },
      ];

      // this.politician.rating = this.politician.percentile_Indicators['total'];

      this.perfilFotoURL = this.storage
        .ref(this.politician.image_path)
        .getDownloadURL();
      //alert("AQUI " + this.politician.pathPol);
      this.radiografiaURL = this.storage
        .ref(this.politician.radiography_path)
        .getDownloadURL();
    });

    // this.cargarBancadas();
  }

  ngAfterContentInit() {
    this.isLoading = false;
  }

  news = [];
  placeholders = [];
  pageSize = 10;
  pageToLoadNext = 1;
  loading = false;
  view: any[] = [900, 300];
  colorScheme = {
    domain: ["#20d073", "#f74c6e", "#C7B42C", "#AAAAAA"],
  };
  viewTransp: any[] = [200, 300];
  gradient = false;
  showXAxis = true;
  showYAxis = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = "";
  showYAxisLabel = true;
  yAxisLabel = "Population";

  onSelect(event) {
    console.log(event);
  }

  // cargarBancadas() {
  //   this.Bancadas.forEach((poldata) => {
  //     this.partidosPoliticos = poldata;
  //     for (let i = 0; i < this.partidosPoliticos.length; i++) {
  //       // console.log("PARTIDOS #"+i+" "+JSON.stringify(this.partidos[i].name)+" "+JSON.stringify(this.partidos[i].path_logo));
  //       this.partidosPoliticos[i].image_path = this.storage
  //         .ref(this.partidosPoliticos[i].image_path)
  //         .getDownloadURL();
  //     }
  //   });
  // }

  
}
